import React, { Component } from 'react';
import { css } from "@emotion/core";
import { Link } from 'gatsby';
import { AuthUserContext } from './Session';
import { withFirebase } from './Firebase';
import * as ROUTES from '../constants/routes';
class MessagesNav extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      messages: [],
      //limit: 50,
      sortByRead: true
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
    this.childRead = this.state.messages.length;
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy('mrkREAD', 'desc')
      //.limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach(doc =>
            messages.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            //collections: PortfoliosList,
            messages: messages.reverse(),
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  }; 

  componentWillUnmount() {
    this.unsubscribe();
  }

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).delete();
  };

  onChangeArchiveTrue = (uid,e) => {
    this.props.firebase.message(uid).update({
      mrkREAD: true
    });
    this.setState({childRead: this.state.messages.length});
  };


  render() {
    const { text, messages,  loading, allusers, msgTitle, sortByRead } = this.state;


    return (
        <AuthUserContext.Consumer>
            {authUser => authUser ? 
                <React.Fragment>                    
                    <Link id="notificationsLink" className="" title="Notifications" to={ROUTES.NOTIFICATIONS} >
                    {this.state.messages.filter(messages => (messages.userId === authUser.uid && messages.mrkREAD === false)).length === 0 && (
                        <span className="icons icon-notifications-bell-open" />
                    )}
                    {this.state.messages.filter(messages => (messages.userId === authUser.uid && messages.mrkREAD === false)).length > 0 && (
                        <span className="unreadMessagesButton">
                            <span className="icons icon-notifications-bell-filled" />
                            <span id="activeCount" className="activeCount">{loading && <span></span>}{this.state.messages.filter(messages => (messages.userId === authUser.uid && messages.mrkREAD === false)).length}</span>
                        </span>
                    )}
                    </Link>
                </React.Fragment>
            : null}
        </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(MessagesNav);